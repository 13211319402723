<template>
  <div class="homework-page mx-2">
    <v-row justify="center">
      <v-col md="6">
        <h3 class="mt-2" v-if="!date">Upcoming</h3>
        <intract-smart-list
          v-if="homeworkEndpoint"
          ref="homework-smart-list"
          disable-search
          filter-classes="mx-0 px-0 mt-1"
          :filter-fields="filterFields"
          paginated
          :endpoint="homeworkEndpoint"
        >
          <template v-slot:custom-filter--dateRange>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  clearable
                  @click:clear="date = null"
                  :value="formattedDateRangeString"
                  label="Choose Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" :range="isDateRange">
                <v-row no-gutters>
                  <v-col cols="12" class="text-center">
                    <v-checkbox
                      v-model="isDateRange"
                      label="Use range of dates"
                    ></v-checkbox>
                    <span
                      v-if="date && date.length == 1"
                      class="text-caption red--text"
                      >Please select End Date as well or select the same date to
                      view Homework of that day only</span
                    >
                  </v-col>
                  <v-col class="text-right">
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      :disabled="date && date.length == 1"
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-date-picker>
            </v-dialog>
          </template>
          <template v-slot:list-item="{ item: homework }">
            <v-card class="my-2">
              <v-list-item
                :to="{
                  name: 'HomeworkDetails',
                  params: { homeworkId: homework.id },
                }"
              >
                <v-list-item-content>
                  <v-list-item-subtitle class="my-1" 
                    >By {{ homework.assignee.full_name }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="primary--text mt-1"
                    ><strong>{{ homework.subject.name }}</strong> -
                    {{ homework.subject.room ? homework.subject.room.sections : "Extra Subject" }}</v-list-item-subtitle
                  >
                  
                  <!-- <v-list-item-subtitle class="my-1 caption">
                    Assigned on {{ moment(homework.created).format("Do MMM") }}
                  </v-list-item-subtitle> -->

                  <v-list-item-title class="my-1">{{
                    homework.description
                  }}</v-list-item-title>
                
                  <v-list-item-subtitle class="text-wrap mt-2">
                    <v-chip
                      class="mt-2 mr-3"
                      small
                      outlined
                      color="blue"
                    >
                      <v-icon left> mdi-clock </v-icon>
                      Assigned:
                      {{ moment(homework.created).format("ll") }}</v-chip
                    >
                    
                    <v-chip
                      class="mt-2"
                      small
                      :color="
                        moment().isAfter(homework.deadline) ? 'red' : 'primary'
                      "
                    >
                      <v-icon left> mdi-clock </v-icon>
                      Deadline:
                      {{ moment(homework.deadline).format("ll") }}</v-chip
                    >
                    </v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="homework.submission_status == 'Pending'"
                    small
                    color="yellow lighten-4"
                    ><v-icon class="mr-1" small>mdi-clock</v-icon>
                    Pending</v-chip
                  >
                  <v-chip
                    v-else-if="homework.submission_status == 'Submitted'"
                    small
                    color="blue lighten-4"
                    ><v-icon class="mr-1" small>mdi-upload</v-icon
                    >Submitted</v-chip
                  >
                  <v-chip
                    v-else-if="homework.submission_status == 'Accepted'"
                    small
                    color="green lighten-4"
                    ><v-icon class="mr-1" small>mdi-check</v-icon
                    >Accepted</v-chip
                  >
                  <v-chip
                    v-else-if="homework.submission_count != null"
                    small
                    color="blue lighten-4"
                    ><v-icon class="mr-1" small>mdi-file-document</v-icon>
                    {{ homework.submission_count }} Submission<span
                      v-if="homework.submission_count > 1"
                      >s</span
                    ></v-chip
                  >
                  <v-icon v-else>mdi-arrow-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </template>
          <template v-slot:paginated-no-items-found>
            <p class="mb-2">No Homework Found!</p>
            <v-btn
              color="primary"
              v-if="!currentUser.is_student"
              @click="createHomeworkSheet.visible = true"
              >Create Homework</v-btn
            >
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- intract create conference sheet -->
    <intract-create-edit-sheet
      v-if="shouldProceed && !currentUser.is_student && formFields"
      key="homework-create-sheet"
      ref="homework-create-sheet"
      title="Create Homework"
      description="Assign homework to students along with a deadline!"
      persistent
      enable-media
      :visible="createHomeworkSheet.visible"
      @close="createHomeworkSheet.visible = false"
      :fields="formFields"
      :endpoint="endpoints.homeworkViewSet"
      :data-object="createHomeworkSheet.obj"
      create-success-message="Homework successfully created!"
      :getObject="finalOperationsBeforeHomeworkCreated"
      @objectCreated="homeworkCreated"
      @updateObject="(obj) => (createHomeworkSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <!-- create conference fab -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="createHomeworkSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- select room dialog -->
    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
import EventBus from "@utils/event_bus";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import { mapGetters } from "vuex";
export default {
  name: "Homework",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    ChooseRoomDialog,
    IntractSmartList,
    IntractCreateEditSheet,
  },
  data() {
    return {
      moment,
      date: null,
      modal: false,
      selectedRoom: null,
      showRoomDialog: false,
      subjects: [],
      homeworkDateRange: [],
      subjectsLoaded: false,
      isDateRange: false,
      createHomeworkSheet: {
        visible: false,
        createSuccessMessage: "Homework created successfully!",
        editId: null,
        obj: {
          assignee: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["userSubjects"]),
    formattedDateRangeString() {
      if (!this.date) return moment().format('ll');

      if (this.date.length == 0) return "";
      if (!this.isDateRange) {
        return moment(this.date).format("ll");
      } else if (this.date.length == 1)
        return moment(this.date[0]).format("ll") + " - ";
      else {
        return (
          moment(this.date[0]).format("ll") +
          " - " +
          moment(this.date[1]).format("ll")
        );
      }
    },
    filterFields() {
      if (!this.subjects) return null;
      var filters = {
        subject: {
          model: null,
          items: this.subjects,
          label: "Filter by Subject",
          itemValue: "id",
          multiple: true,
          itemText: "name",
          // itemSubtitle: "abc",
          param: "subject__in",
          getSubtitle:  (item) => item.room ? item.sections : "Extra Subject",
          
          //   dynamicParam: true
        },
        status: {
          model: null,
          items: [
            { title: "Pending", value: 1 },
            { title: "Submitted", value: 2 },
            { title: "Accepted", value: 3 },
          ],
          label: "Filter by Status",
          itemValue: "value",
          itemText: "title",
          param: "submission_status",
          //   dynamicParam: true
        },
        dateRange: {
          model: null,
          items: [],
          label: "Filter by Date",
          // itemValue: "id",
          custom: true,
          // itemText: "name",
          // param: "subject__in",
          //   dynamicParam: true
        },

        // user__room: {
        //   model: null,
        //   items: this.allRooms,
        //   label: "Filter by Classroom",
        //   itemValue: "id",
        //   itemText: "sections",
        //   param: "user__room__in",
        // },
      };

      if (this.currentUser.is_faculty || this.currentUser.is_admin)
        delete filters.status;
      return filters;
    },
    shouldProceed() {
      return (
        ((this.currentUser.is_admin || this.currentUser.is_student) &&
          this.selectedRoom) ||
        (this.currentUser.is_faculty && !this.currentUser.is_admin)
      );
    },
    homeworkEndpoint() {
      if (!this.shouldProceed) return null;
      var deadlineFilter =
        "deadline__gte=" + moment().format(this.Helper.dateURLFormat);
      // if date filter added then homework should be shown of that period only
      if (this.date && (this.date.length == 2 || !this.isDateRange) ) {
        var startDate,
          endDate = null;
          if(!this.isDateRange) startDate = endDate = this.date;
        else if (moment(this.date[0]).isSameOrBefore(moment(this.date[1]))) {
          startDate = this.date[0];
          endDate = this.date[1];
        } else {
          startDate = this.date[1];
          endDate = this.date[0];

        }
        startDate = moment(startDate + " " + '00:00:00');
        endDate = moment(endDate + " " + '23:59:59');
        deadlineFilter = `deadline__gte=${startDate.format(
          this.Helper.dateURLFormat
        )}&created__lte=${endDate.format(this.Helper.dateURLFormat)}`;
      } 

      var url = this.Helper.addUrlParams(this.endpoints.homeworkViewSet, [
        "ordering=deadline",
        deadlineFilter,
      ]);
      if (this.currentUser.is_admin || this.currentUser.is_student)
        return this.Helper.addUrlParams(url, [
          "subject__room=" + this.selectedRoom.id,
        ]);
      else if (this.currentUser.is_faculty)
        return this.Helper.addUrlParams(url, [
          "subject__faculty__id=" + this.currentUser.id,
        ]);
      else return null;
    },
    subjectsEndpoint() {
      if (!this.shouldProceed) return null;
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },
    formFields() {
      if (!this.homeworkEndpoint || !this.subjectsLoaded) return null;
      return {
        subject: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Subject",
          md: 6,
          required: true,
          //   endpoint: this.subjectsEndpoint,
          items: this.subjects,
          itemText: "name",
          itemValue: "id",
          getSubtitle:  (item) => item.room ? item.sections : "Extra Subject",
          // itemSubtitle: "room.sections",
        },
        deadline: {
          fieldType: CreateEditFieldTypes.DATEPICKER,
          textModel: null,
          min: new Date(),
          label: "Deadline",
          helper: "When would be the last date of submission?",
          required: true,
          md: 6,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        homework_documents: {
          camera: true,
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    finalOperationsBeforeHomeworkCreated(obj) {
      if (obj.deadline) obj.deadline = moment(obj.deadline).format();
      return obj;
    },

    async homeworkCreated(homeworkObj) {
      await this.onRefresh();
      var self = this;
      setTimeout(() => {
        self.$router.push({
          name: "HomeworkDetails",
          params: { homeworkId: homeworkObj.id },
        });
      }, 1000);
    },

    async getAllSubjects() {
      if (this.currentUser.is_admin) {
        this.subjects = await this.api.call(
          this.essentials,
          this.subjectsEndpoint
        );
      } else {
        this.subjects = this.userSubjects;
      }

      this.subjectsLoaded = true;
    },
    async setListeners() {
      EventBus.$on("homework__homework_updated", (homework) => {
        if (this.$refs["homework-smart-list"])
          this.$refs["homework-smart-list"].replaceItem(homework.id, homework);
      });
      EventBus.$on("homework__homework_deleted", (deletedId) => {
        if (this.$refs["homework-smart-list"])
          this.$refs["homework-smart-list"].removeItem(deletedId);
      });
    },

    async onRefresh() {
      await this.getAllSubjects();
      console.log(this.$refs);
      if (this.$refs["homework-smart-list"]) {
        console.log("going here");
        this.$refs["homework-smart-list"].getItems();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      EventBus.$emit("keep_alive__destroy_view", "Homework");
    }
    next();
  },
  async created() {
    this.setListeners();
    this.showRoomDialog = this.currentUser.is_admin; // only show if admin
    if (this.currentUser.is_faculty && !this.currentUser.is_admin)
      await this.getAllSubjects(); // only call for faculty, because for others, watch is doing the job
    if (!this.currentUser.is_student) {
      this.createHomeworkSheet.obj.assignee = this.currentUser.id;
    }
  },
  watch: {
    subjectsEndpoint: "getAllSubjects",
  },
};
</script>